import axios from "../../../services/Api";

export default {
  methods: {
    // funcion handleLogin, primero get de sanctum para generar cookie y luego endpoint de login con los datos del userForm

    async handleLogin() {
      this.loading = true;
      await axios.get("/sanctum/csrf-cookie");
      await axios
        .post("front-catalog/login", this.userForm)
        .then(() => {
          this.signIn();
          this.loading = false;
        })
        .catch(() => {
          this.wrongCredentials = true;
          this.loading = false;
        });
    },
  },
};
